import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { Eject } from "@material-ui/icons";
import { useAppState } from "store/AppState";
import useHit from "hooks/useHit";
import APIEndpoints from "APIEndpoints";

const UpvoteButton = ({
  postId, commentId, initialValue, voteCount, ...props
}) => {
  const hit = useHit();
  const [isLiked, setLiked] = useState(initialValue);
  const realVoteCount = voteCount - Number(!!initialValue);
  const [loading, setLoading] = useState(false);
  const { upvotes, setUpvotes } = useAppState();
  const computedAPIEndpoint = commentId ? APIEndpoints.COMMENTS : APIEndpoints.POSTS;
  const computedId = commentId || postId;

  useEffect(() => {
    if (typeof upvotes[postId] === "boolean") {
      setLiked(upvotes[postId]);
    }
  }, [upvotes, postId]);

  const like = async () => {
    setLoading(true);
    const { error } = await hit(computedAPIEndpoint.ADD_VOTE(computedId));
    setLoading(false);
    if (!error) {
      setLiked(true);
      setUpvotes({ ...upvotes, [postId]: true });
    }
  };

  const unlike = async () => {
    setLoading(true);
    const { error } = await hit(computedAPIEndpoint.REMOVE_VOTE(computedId));
    setLoading(false);
    if (!error) {
      setLiked(false);
      setUpvotes({ ...upvotes, [postId]: true });
    }
  };

  return (
    <Button
      size="small"
      disabled={loading}
      color={isLiked ? "primary" : "default"}
      onClick={isLiked ? unlike : like}
      startIcon={<Eject />}
      {...props}
    >
      <Typography variant="caption">
        {realVoteCount + Number(!!isLiked)}
      </Typography>
    </Button>
  );
};

export default UpvoteButton;
