import { Box, Chip, Typography } from "@material-ui/core";
import HorizontalArragement from "./HorizontalArragement";
import HorizontalScroll from "./HorizontalScroll";

const TopicArragement = ({
  topics, chipMargen = 0,
  variant = "default",
  onClick = () => null, selectedValues = null,
  ...props
}) => (
  <HorizontalScroll {...props}>
    <HorizontalArragement maxWidth="100%">
      {topics.map((topic) => (
        <Box my={chipMargen} key={topic.id}>
          <Chip
            size="small"
            label={(
              <Typography variant="caption">
                {topic.topicId || topic.id}
              </Typography>
            )}
            variant={variant}
            color={selectedValues?.find((_topic) => _topic === (topic.topicId || topic.id)) ? "primary" : "default"}
            onClick={() => onClick(topic.topicId || topic.id)}
          />
        </Box>
      ))}
    </HorizontalArragement>
  </HorizontalScroll>
);

export default TopicArragement;
