import { Box, Hidden, Link, makeStyles, Typography, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import UpvoteButton from "components/MagicButtons/UpvoteButton";
import HorizontalArragement from "components/HorizontalArragement";
import Thumbnail from "components/Thumbnail";
import TopicArragement from "components/TopicArragement";
import * as routes from "routes";
import VerticalArragement from "components/VerticalArragement";
import SizableAvatar from "components/SizableAvatar";
import formatDistance from "date-fns/formatDistanceToNowStrict";
import localeEs from "date-fns/locale/es";
import { useTranslation } from "i18n";
import ChildrenFriendlyButton from "components/ChildrenFriendlyButton";

const useStyles = makeStyles((theme) => ({
  itemBody: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "left",
  },
  innerContainer: {
    flexGrow: 1,
  },
  flexStart: {
    alignItems: "flex-start",
  },
}));

const FeedItem = ({
  id,
  thumbnail,
  title,
  subtitle,
  topics = [],
  commentCount,
  voteCount,
  userVote,
  pretext,
  user,
  createdAt,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <HorizontalArragement className={classes.container}>
      <UpvoteButton voteCount={voteCount} postId={id} initialValue={userVote?.value} />
      <VerticalArragement className={classes.innerContainer}>
        <HorizontalArragement>
          <SizableAvatar
            size={25}
            src={user.avatar}
            isRatherMember={user.israthermember}
          />
          <Typography variant="caption">
            <Link color={theme.palette.type === "light" ? "primary" : "inherit"} href={routes.PROFILE(user.id)}>
              {user.username}
            </Link>
            {" "}
            -
            {" "}
            {formatDistance(new Date(createdAt), { addSuffix: true, locale: localeEs })}
          </Typography>
        </HorizontalArragement>
        <Link
          href={routes.POST(id)}
          passHref
          style={{ textDecoration: "none" }}
        >
          <ChildrenFriendlyButton noPadding fullWidth>
            <VerticalArragement>
              <HorizontalArragement className={classes.flexStart} justifyContent="space-between">
                <VerticalArragement>
                  <Box>
                    <Typography className="bold">
                      {title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >
                      {subtitle}
                    </Typography>
                  </Box>
                  {pretext && (
                    <Typography variant="caption" color="textSecondary">
                      {`${pretext.slice(0, 200)}...`}
                    </Typography>
                  )}
                </VerticalArragement>
                <Hidden xsDown>
                  <Thumbnail src={thumbnail} />
                </Hidden>
              </HorizontalArragement>
              <HorizontalArragement justifyContent="space-between">
                <TopicArragement topics={topics} />
                <HorizontalArragement spacing={0.5}>
                  <Typography variant="caption">
                    {commentCount}
                    {" "}
                    {t("COMMENTS")}
                  </Typography>
                </HorizontalArragement>
              </HorizontalArragement>
            </VerticalArragement>
          </ChildrenFriendlyButton>
        </Link>
      </VerticalArragement>
    </HorizontalArragement>
  );
};

const FeedSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.item} fullWidth>
      <Skeleton
        variant="circle"
        height={50}
        width={50}
      />
      <Box className={classes.itemBody}>
        <Skeleton width={40} height={10} />
        <Skeleton width={80} height={10} />
      </Box>
      <Skeleton width={30} height={30} />
    </Box>
  );
};

FeedItem.Skeleton = FeedSkeleton;

export default FeedItem;
