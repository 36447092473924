import { makeStyles, Avatar, Badge, Tooltip, Typography } from "@material-ui/core";
import { useTranslation } from "i18n";
import RatherMember from "./Icons/RatherMember";

const useStyles = makeStyles({
  sizedAvatar: ({ size }) => ({
    width: size || 40,
    height: size || 40,
  }),
});

const SizableAvatar = ({ size, bagdeSize = "small", isRatherMember, ...rest }) => {
  const classes = useStyles({ size });
  const { t } = useTranslation();

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        isRatherMember && (
          <Tooltip color="primary" title={<Typography variant="caption" color="white">{t("RATHER_COINS")}</Typography>} arrow>
            <RatherMember fontSize={bagdeSize} />
          </Tooltip>
        )
      }
    >
      <Avatar
        className={classes.sizedAvatar}
        {...rest}
      />
    </Badge>
  );
};

export default SizableAvatar;
