import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));

const BaseScreen = ({
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Container
      maxWidth="lg"
      className={classes.container}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default BaseScreen;
