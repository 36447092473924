import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const useStyles = makeStyles({
  container: ({ spacing }) => ({
    "& > *:not(:last-child)": {
      marginBottom: 8 * spacing,
    },
  }),
});
  
const VerticalArragement = ({ spacing = 1, className, ...props }) => {
  const classes = useStyles({ spacing });
  return (
    <Box className={clsx(classes.container, className)} {...props} />
  );
};

export default VerticalArragement;
