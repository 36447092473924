import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import useResource from "hooks/useResource";
import { Typography } from "@material-ui/core";
import { useTranslation } from "i18n";
import VerticalArragement from "./VerticalArragement";

const RemoteList = ({
  endpoint,
  ContainerElement,
  paginated,
  onChange,
  Item,
  Skeleton,
  ...rest }, ref) => {
  const { data, mutate } = useResource(endpoint);
  const { t } = useTranslation();
  const elements = data && (paginated ? data.rows : data);

  useEffect(() => {
    if (data && onChange) onChange(data);
  }, [data, onChange]);

  useImperativeHandle(ref, () => ({
    refresh: () => mutate(data),
  }));

  const Container = ContainerElement || VerticalArragement;

  return (
    <Container {...rest}>
      {data
        ? elements.map((item) => <Item key={item.id} {...item} />)
        : Array(3).fill().map(() => (Skeleton ? <Skeleton /> : null))}
      {data && !elements.length && (
        <Typography color="textSecondary">
          {t("NO_RESULTS_FOUND")}
        </Typography>
      )}
    </Container>
  );
};

export default forwardRef(RemoteList);
